<template>
  <div class="personal-center">
    <div class="wrapper">
      <div class="sidebar-container" v-show="screenWidth > 992 || showMenu">
        <div class="sidebar">
          <div class="info" v-if="userInfo">
            <img
              loading="lazy"
              class="profile img80"
              :src="
                userInfo.avatar
                  ? userInfo.avatar
                  : require('@/assets/img/personal/init-profile.svg')
              "
              alt="profile"
            />
            <div>
              <p class="username">
                {{ userInfo.first_name }}&nbsp;{{ userInfo.last_name }}
              </p>
              <p class="email">{{ userInfo.email }}</p>
            </div>
            <p
              class="p-btn logout-btn"
              v-if="screenWidth < 992"
              @click="handleLogout($event)"
              :class="aborting ? 'isLoading' : ''"
            >
              {{ $t("home.logout") }}
            </p>
          </div>
          <el-menu
            :default-active="activeMenu"
            class="sidebar-menu"
            @select="handleSelect"
          >
            <el-submenu
              v-for="item in menuItems"
              :key="item.index"
              :index="item.index"
            >
              <template slot="title">{{ item.title }}</template>
              <el-menu-item
                v-for="child in item.children"
                :key="child.index"
                :index="child.index"
              >
                {{ child.title }}
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
      </div>
      <div class="component-container" ref="container">
        <tf-advertise
          v-if="
            userInfo?.is_show_pop && vendorInfo.header == 'tf' && showAdvertise
          "
          :data="vendorInfo"
          @close="closeAdvertise"
        />
        <div v-if="!currentComponent.untitled" class="component-header">
          <p class="component-title">
            {{ currentComponent?.title }}
          </p>
        </div>
        <loading v-show="isRequest"></loading>
        <component
          class="content-component"
          ref="component"
          v-if="userInfo"
          v-show="!isRequest"
          :is="currentComponent?.e"
          :userInfo="userInfo"
        />
        <popup
          :isError="isError"
          :isSuccess="isSuccess"
          :isWarning="isWarning"
          :isCustom="isCustom"
          :customDetail="customDetail"
          :text="popupText"
          :isWaiting="isWaiting"
          :customConfirm="customConfirm"
        ></popup>
      </div>

      <celebration-popup
        v-if="showCelebration"
        @close="showCelebration = false"
      />
    </div>
  </div>
</template>

<script>
import userInfoComponent from "./components/modules/userInfo.vue";
import subscription from "./components/modules/subscription.vue";
import manage from "./components/modules/manage.vue";
import billing from "./components/modules/billing.vue";
import contact from "./components/modules/contact.vue";
import subaccount from "./components/modules/subaccount.vue";
import cart from "./components/modules/cart.vue";
import updatePwd from "./components/modules/updatePwd.vue";
import comingSoon from "./components/modules/comingSoon.vue";
// import videoDownloader from "./components/modules/videoDownloader.vue";
import spotifyDownloader from "./components/modules/spotifyDownloader.vue";
import celebrationPopup from "./components/celebrationPopup.vue";

import loading from "@/components/loading.vue";
import Popup from "@/components/popup.vue";

import { mapGetters } from "vuex";
import { getUserInfo, closePop } from "@/api/user.js";
import { jwtLogin } from "@/api/login.js";
import { logout } from "@/api/login.js";
import { getPurchasedProduct, getPurchasedPlan } from "@/api/dmapi/user.js";
import { updateData } from "@/utils/parse.js";

export default {
  name: "personalCenter",
  components: {
    userInfoComponent,
    subscription,
    manage,
    billing,
    contact,
    subaccount,
    Popup,
    updatePwd,
    cart,
    comingSoon,
    spotifyDownloader,
    loading,
    tfAdvertise: () => import("./components/advertise/tf.vue"),
    celebrationPopup,
  },
  data() {
    return {
      menuItems: [],
      userInfo: null,
      activeMenu: "",
      currentComponent: null,
      allComponentsInfo: {
        userInfo: {
          e: userInfoComponent,
          title: this.$t("psersonal.personal_info"),
          id: "user",
        },
        subscription: {
          e: subscription,
          title: this.$t("psersonal.subscription"),
          id: "subscription",
        },
        manage: {
          e: manage,
          title: this.$t("psersonal.manage_devices"),
          id: "manage-devices",
        },
        billing: {
          e: billing,
          title: this.$t("psersonal.billing"),
          id: "billing",
        },
        contact: {
          e: contact,
          title: this.$t("psersonal.contact_support"),
          id: "contact-support",
        },
        subaccount: {
          e: subaccount,
          title: this.$t("psersonal.sub_account"),
          id: "subaccount",
        },
        updatePwd: {
          e: updatePwd,
          title: this.$t("psersonal.change_pwd"),
          id: "change-password",
        },
        cart: {
          e: cart,
          title: this.$t("psersonal.shopping_cart"),
          id: "cart",
        },
        smc: {
          e: spotifyDownloader,
          title: this.$t("psersonal.spotify_converter"),
          untitled: true,
          id: "spotify-to-mp3",
        },
        ymc: {
          e: comingSoon,
          title: this.$t("psersonal.youtube_converter"),
          untitled: true,
          id: "youtube-music-to-mp3",
        },
        amc: {
          e: comingSoon,
          title: this.$t("psersonal.apple_converter"),
          untitled: true,
          id: "apple-music-to-mp3",
        },
      },
      showAdvertise: true,
      showCelebration: false,

      // popup
      popupText: "",
      isWarning: false,
      isSuccess: false,
      isError: false,
      isCustom: false,
      isWaiting: false,
      customConfirm: null,
      customDetail: null,

      vhShowMenu: false,
      innerWidth: window.innerWidth,

      isRequest: false,
      aborting: false,

      manageSeletedInfo: {
        softwareId: "",
        softwareName: "",
        subId: "",
      },

      purchasedInfo: null,
    };
  },
  computed: {
    hasPopup() {
      return this.isSuccess || this.isWarning || this.isError;
    },
    ...mapGetters([
      "showMenu",
      "screenWidth",
      "systemHasLogin",
      "accountInfo",
      "isPro",
      "vendorInfo",
    ]),
  },
  methods: {
    handleSelect(val, tracking = true) {
      const index = val || "0-0";
      this.activeMenu = index;
      const [i, j] = index.split("-");
      this.currentComponent = this.menuItems[i].children[j];
      this.$router.replace({ path: `/personal#${this.currentComponent.id}` });
      this.$store.commit("dmCommon/changeMenuStatus", false);
      if (tracking && this.menuItems.length > 1 && i == 0) {
        this.$eventTracking({
          event: `Click${this.currentComponent.id
            .split("-")[0]
            .replace(/^\w/, match => match.toUpperCase())}`,
        });
      }
    },
    handleConfirm() {
      this.$refs.component.handleConfirm();
    },
    getUserInfomartion() {
      getUserInfo().then(response => {
        this.userInfo = response?.data;
        this.$store.commit("user/setUserInfo", response.data);
        updateData({
          email: this.userInfo.email,
          vip_userID: response.data.id.toString(),
        });
      });
    },
    handleLogout(e) {
      this.aborting = true;
      logout()
        .then(() => {
          this.aborting = false;
          this.$store.commit("dmCommon/changeLoginStatus", false);
          this.$navigateTo(e, "/login");
          sessionStorage.removeItem("email");
          this.$store.commit("user/setUserInfo", null);
          this.$cookieManager.removeCookie("_token");
          // removeCookie("_token");
        })
        .catch(() => {})
        .finally(() => {
          this.aborting = false;
        });
    },
    getPurchasedList() {
      const api = this.vendorInfo.planSearch
        ? getPurchasedPlan
        : getPurchasedProduct;
      api()
        .then(res => {
          this.purchasedInfo = res.data.list;
        })
        .catch(() => {});
    },
    initMenu() {
      const indexModules =
        this.isPro && this.vendorInfo.proIndexModules
          ? this.vendorInfo.proIndexModules
          : this.vendorInfo.indexModules;
      const defaultIndexModules = {
        my_account: [
          "userInfo",
          "subscription",
          "manage",
          "contact",
          "updatePwd",
        ],
      };
      const showList = indexModules || defaultIndexModules;
      Object.keys(showList).forEach((key, index) => {
        this.menuItems.push({
          title: this.$t(key),
          index: `${index}`,
          children: showList[key].map((item, i) => {
            return {
              index: `${index}-${i}`,
              ...this.allComponentsInfo[item],
            };
          }),
        });
      });
      this.currentComponent = this.menuItems[0].children[0];
    },
    closeAdvertise() {
      closePop();
      this.showAdvertise = false;
    },
    changeModule(id) {
      const module = this.menuItems
        .flatMap(item => item.children)
        .find(item => item.id == id);
      this.handleSelect(module.index, false);
    },
  },
  created() {
    if (this.$route.query.token) {
      this.$eventTracking({ event: "OpenWeb" }, ["软件"]);
      jwtLogin(this.$route.query.token)
        .then(res => {
          this.$cookieManager.setCookie("_token", res.data, 1);
          this.$store.commit("dmCommon/changeLoginStatus", true);
          this.accountInfo
            ? (this.userInfo = this.accountInfo)
            : this.getUserInfomartion();
          this.$router.replace("personal");
          this.getPurchasedList();
        })
        .catch(() => {
          this.$navigateTo({ text: "软件登录链接验证失败" }, "/login");
        });
    } else {
      this.accountInfo
        ? (this.userInfo = this.accountInfo)
        : this.getUserInfomartion();
      this.purchasedInfo ? "" : this.getPurchasedList();
    }

    this.initMenu();
  },
  mounted() {
    if (!this.systemHasLogin && !this.$route.query.token) {
      let timer = setTimeout(() => {
        this.$navigateTo({ text: "账号未登录" }, "/login");
        clearTimeout(timer);
      }, 500);
      return;
    }
    this.changeModule(
      this.$route.hash ? this.$route.hash?.slice(1) : "subscription"
    );
    if (this.vendorInfo.header == "tf") {
      this.showCelebration = JSON.parse(sessionStorage.getItem("celebrate"));
      sessionStorage.getItem("smcParseUrl") &&
        this.changeModule("spotify-to-mp3");
    }

    window.addEventListener("message", e => {
      const d = e.data;
      switch (d.event) {
        case "changeMenuStatus":
          this.vhShowMenu = d.value;
          break;
      }
    });
    window.addEventListener("resize", () => {
      this.innerWidth = window.innerWidth;
    });
  },
};
</script>

<style lang="scss">
@import "@/styles/personal";
</style>
