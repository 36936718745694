<template>
  <div class="coming-soon">
    <img src="@/assets/img/coming-soon/notify.webp" alt="notify" />
    <p class="bold">{{ $t("coming-soon.were-coming-soon") }}</p>
    <p class="gray">{{ $t("coming-soon.be-the-first-one-to-know") }}</p>
    <p
      :class="[
        'p-btn',
        notifyStatus == 'ing' && 'loading',
        notifyStatus == 'success' && 'success',
      ]"
      @click="handleSubcribe"
    >
      {{ $t("coming-soon.notify-me") }} <span></span>
    </p>
  </div>
</template>

<script>
export default {
  name: "ComingSoon",
  props: {
    userInfo: {
      type: Object,
    },
  },
  data() {
    return {
      notifyStatus: "", // '' | 'ing' | 'success'
    };
  },

  methods: {
    handleSubcribe() {
      if (this.notifyStatus) return;
      this.notifyStatus = "ing";
      this.$eventTracking(
        {
          event: "ClickNotifyMe",
          email: this.userInfo.email,
          location: this.$route.hash.includes("youtube")
            ? "YoutubeWindows"
            : "AppleWindows",
        },
        [],
        () => {
          this.notifyStatus = "success";
        }
      );
      const timer = setTimeout(() => {
        this.notifyStatus = "";
        clearTimeout(timer);
      }, 3000);
    },
  },
};
</script>

<style lang="scss">
.coming-soon {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  img {
    max-width: 360px;
    width: auto;

    @include breakpoint-max(md) {
      max-width: 240px;
    }
  }

  .bold {
    @include font("md", 26px, 32px, 900);
    @include font("base", 22px, 26px, 900);

    margin: 30px 0 8px;
  }

  .gray {
    @include font("md", 16px, 22.4px, 600);
    @include font("base", 14px, 22.4px, 600);

    color: #717171;
  }

  .p-btn {
    margin-top: 16px;
    color: #fff;
    background: #3e6de8;
    padding: 12px 20px;
    gap: 10px;
    width: 164px;

    @include font("base", 16px, 19.2px, 500);

    @include hoverStyle {
      background: linear-gradient(0deg, rgb(0 0 0 / 20%), rgb(0 0 0 / 20%)),
        linear-gradient(0deg, #3e6de8, #3e6de8);
    }

    &.loading {
      cursor: no-drop;

      span {
        --d: 8px;

        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        color: #3e6de8;
        box-shadow: calc(1 * var(--d)) calc(0 * var(--d)) 0 -0.6px #fff,
          calc(0.707 * var(--d)) calc(0.707 * var(--d)) 0 -0.3px #fff,
          calc(0 * var(--d)) calc(1 * var(--d)) 0 0px #fff,
          calc(-0.707 * var(--d)) calc(0.707 * var(--d)) 0 0.1px #fff,
          calc(-1 * var(--d)) calc(0 * var(--d)) 0 0.3px #fff,
          calc(-0.707 * var(--d)) calc(-0.707 * var(--d)) 0 0.4px #fff,
          calc(0 * var(--d)) calc(-1 * var(--d)) 0 0.5px #fff;
        animation: rotate 1s infinite steps(7);
        margin: 8px;

        @keyframes rotate {
          100% {
            transform: rotate(1turn);
          }
        }

        @include breakpoint-max(sm) {
          --d: 6px;

          width: 2px;
          height: 2px;
          margin: 0 11px 4px 0;
        }
      }
    }

    &.success {
      cursor: no-drop;

      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url("~@/assets/img/coming-soon/check.svg") no-repeat;
        background-size: contain;
      }
    }
  }
}
</style>
