<template>
  <div class="module-container">
    <img class="left-top" src="@/assets/img/login/dots1.png" alt="dots" />
    <img class="right-bottom" src="@/assets/img/login/dots2.png" alt="dots" />
    <div class="wrapper">
      <div>
        <div class="content-left">
          <div v-if="vendorInfo.header == 'tf'" class="join">
            <img src="@/assets/img/login/fireworks.png" alt="fireworks" />
            <div>
              <p
                v-html="
                  $t('login.join_free_download', {
                    name: vendorInfo.name,
                  })
                "
              ></p>
              <p>{{ $t("login.new_users_only") }}</p>
            </div>
            <img src="@/assets/img/login/fireworks.png" alt="fireworks" />
          </div>
          <component
            class="module-component"
            :is="name"
            :fp="fingerPrint"
          ></component>
        </div>
        <div class="content-right">
          <div class="gradient-text">
            <span class="stroke">{{ $t("login.bset_solution") }}</span>
            <span class="text" :text="$t('login.bset_solution')">
              {{ $t("login.bset_solution") }}
            </span>
          </div>
          <div class="multimedia">
            <img src="@/assets/img/login/multimedia.webp" alt="multimedia" />
            <img
              v-if="vendorInfo.header == 'stb'"
              class="stb-logo"
              src="@/assets/img/website/streambyte/logo-without-name.svg"
              alt="logo"
            />
            <img v-else :src="vendorInfo.logo" alt="logo" />
          </div>
        </div>
      </div>
    </div>
    <popup
      :isError="isError"
      :isSuccess="isSuccess"
      :isWarning="isWarning"
      :text="popupText"
      :accountPresence="accountPresence"
    ></popup>
  </div>
</template>

<script>
import Login from "./components/modules/login.vue";
import Register from "./components/modules/register.vue";
import resetPassword from "./components/modules/resetPassword.vue";
import Popup from "@/components/popup.vue";
import { mapGetters } from "vuex";
import cryptojs from "crypto-js";

export default {
  name: "loginModule",
  components: { Login, Register, resetPassword, Popup },
  props: {
    name: {
      type: String,
      default: "login",
    },
  },
  data() {
    return {
      popupText: "",
      isError: false,
      isSuccess: false,
      isWarning: false,
      accountPresence: false,
      fingerPrint: "",
    };
  },
  computed: {
    ...mapGetters(["systemHasLogin", "vendorInfo"]),
  },
  methods: {
    getFingerPrint() {
      window.fpVersion = "1.0.0";
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      ctx.textBaseline = "top";
      ctx.font = "14px Arial";
      ctx.fillText("G7h@5kLp.9#zQ3tR@8sW", 2, 2);
      ctx.fillStyle = "rgba(255, 0, 0, 0.5)";
      ctx.fillRect(0, 0, 200, 100);
      const dataUrl = canvas.toDataURL();
      const fp = cryptojs.MD5(dataUrl).toString();
      localStorage.setItem("fingerPrint", fp);
      localStorage.setItem("fpVersion", window.fpVersion);
      return fp;
    },
  },
  mounted() {
    // 处理参数标识
    const query = this.$route.query;
    query.source && this.$eventTracking({ event: "OpenWeb" }, [query.source]);
    sessionStorage.setItem("celebrate", query.source == "onlinesmc");
    query.smcParseUrl &&
      sessionStorage.setItem("smcParseUrl", query.smcParseUrl);

    if (this.systemHasLogin && this.name != "resetPassword") {
      this.$navigateTo({ text: "已登录" }, "/personal");
    }
    this.$nextTick(() => {
      this.fingerPrint = this.getFingerPrint();
    });
  },
};
</script>

<style lang="scss">
@import "@/styles/login/loginIndex";
@import "@/styles/login/loginModule";
</style>
